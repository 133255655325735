import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_AUTHENTICATION_FEATURE_KEY, AuthState } from './authentication.reducer';

export const getStateAuthentication = createFeatureSelector<AuthState>(
	_STATE_AUTHENTICATION_FEATURE_KEY,
);

export const getAuthStatus = createSelector(
	getStateAuthentication,
	(state: AuthState) => {
		return {
			isLoggedIn: !!state.authData?.userId,
			role: state.authData?.role,
			error: state.error,
		};
	},
);
