import { Inject, Injectable, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Request } from 'express';
import { CustomCookieService } from './services/custom-cookie.service';
import { REQUEST } from './ssr-headers/headers.config';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		@Optional() @Inject(REQUEST) private _request: Request,
		private _customCookieSrv: CustomCookieService,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const url = req.url;

		if (this._request?.headers?.cookie) {
			req = req.clone({
				setHeaders: {
					Cookie: this._request.headers.cookie,
				},
			});
		}

		// append authorisation header if going to new endpoint.
		if (url.includes('/api') && !url.includes('api.ipify.org')) {
			const token = this._customCookieSrv.get('token');

			if (token) {
				req = req.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
			}
		}

		return next.handle(req);
	}
}
