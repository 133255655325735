import { createAction, props } from '@ngrx/store';
import { AuthData } from '../models/authentication.models';

export const updateAuthState = createAction(
	'[Authentication] Update Auth Data',
	props<{ authData: AuthData | null }>(),
);

//logout
export const initLogout = createAction('[Authentication] Start Logout');

export const completeLogout = createAction('[Authentication] Complete Logout');

export const errorLogin = createAction('[Authentication] Login Error', props<any>());
