export interface LoginData {
	user_id: string;
	avatar: string;
	token: string;
	role: RoleType | null;
}

export interface AuthData {
	role: RoleType | null;
	userId: string;
}

export enum Role {
	STUDENT = 'STUDENT',
	CLIENT = 'CLIENT',
}

export type RoleType = `${Role}`;

export type CookieStoreKeys = keyof LoginData;
