import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Request } from 'express';
import { REQUEST } from '../ssr-headers/headers.config';
import { cookies } from '../static/authentication.properties';
import { CookieStoreKeys } from '../models/authentication.models';

@Injectable({
	providedIn: 'root',
})
export class CustomCookieService {
	private cookieStore = { ...cookies };

	constructor(
		@Inject(PLATFORM_ID) private _platformId: object,
		@Optional() @Inject(REQUEST) private _request: Request,
		@Optional() @Inject(DOCUMENT) private _document: Document,
	) {
		this.initialiseCookies();
	}

	get(key: CookieStoreKeys) {
		return this.cookieStore[key] ? this.cookieStore[key] : null;
	}

	getAll() {
		return { ...this.cookieStore };
	}

	reset() {
		this.cookieStore = { ...cookies };
	}

	initialiseCookies() {
		if (isPlatformServer(this._platformId) && this._request?.headers?.cookie) {
			this._parseCookies(this._request.headers.cookie);
		} else if (isPlatformBrowser(this._platformId) && this._document?.cookie) {
			this._parseCookies(this._document.cookie);
		}
	}

	private _parseCookies(cookies: any) {
		if (!!cookies === false) {
			return;
		}
		const cookiesArr = cookies.split(';');
		for (const cookie of cookiesArr) {
			const cookieArr = cookie.split('=');
			const key = cookieArr[0].trim();
			if (key.startsWith('uc-')) {
				const value = decodeURIComponent(cookieArr[1]);
				const formattedKey = key.replace('uc-', '');
				(this.cookieStore as any)[formattedKey] = value;
			}
		}
	}
}
