import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationService } from '../services/authentication.service';
import { map, tap } from 'rxjs';
import { completeLogout, initLogout } from './authentication.actions';

@Injectable()
export class StateAuthEffects {
	constructor(
		private readonly _actions$: Actions,
		private _authSrv: AuthenticationService,
	) {}

	logoutUser = createEffect(() =>
		this._actions$.pipe(
			ofType(initLogout),
			//remove cookies
			tap(() => this._authSrv.signOut()),
			//reset state
			map(() => completeLogout()),
		),
	);
}
