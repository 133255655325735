import { PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Role } from '../models/authentication.models';
import { CustomCookieService } from '../services/custom-cookie.service';

export const loginGuard = () => {
	const router = inject(Router);
	const platformId = inject(PLATFORM_ID);
	const cookieSrv = inject(CustomCookieService);
	const data = cookieSrv.getAll();
	const isBrowser = isPlatformBrowser(platformId);

	if (!data.token) {
		return true;
	}
	if (data?.role === Role.STUDENT) {
		if (isBrowser) {
			router.navigate(['/']);
		}
		return false;
	}
	if (data?.role === Role.CLIENT) {
		if (isBrowser) {
			location.href = `/dashboard`;
		}
		return false;
	}
	return true;
};
