import { PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../models/authentication.models';
import { isPlatformBrowser } from '@angular/common';
import { CustomCookieService } from '../services/custom-cookie.service';

export const accountAccessGuard = () => {
	const router = inject(Router);
	const platformId = inject(PLATFORM_ID);
	const cookieSrv = inject(CustomCookieService);
	const data = cookieSrv.getAll();
	if (!data.token) {
		router.navigate(['/login']);
		return false;
	}
	if (data?.role === Role.CLIENT) {
		if (isPlatformBrowser(platformId)) {
			location.href = `/dashboard`;
		}
		return false;
	}
	return true;
};
