import { createReducer, on, Action } from '@ngrx/store';
import {
	completeLogout,
	errorLogin,
	initLogout,
	updateAuthState,
} from './authentication.actions';
import { AuthData } from '../models/authentication.models';

export const _STATE_AUTHENTICATION_FEATURE_KEY = 'stateAuthentication';

export interface AuthState {
	authData: AuthData | null;
	error: any;
}

export const initialState: AuthState = {
	authData: null,
	error: null,
};

const stateAuthenticationReducer = createReducer(
	initialState,
	on(updateAuthState, (state, { authData }) => ({
		...state,
		authData,
		error: null,
	})),
	//logout
	on(initLogout, (state) => ({
		...state,
		error: null,
	})),
	on(completeLogout, (state) => ({
		...state,
		userData: null,
	})),
	//error
	on(errorLogin, (state, { error }) => ({
		...state,
		error,
	})),
);

export function authenticationReducer(state: AuthState | undefined, action: Action) {
	return stateAuthenticationReducer(state, action);
}
